import React, { Component } from 'react'
import queryString from 'query-string'
import {getPost} from '../../store/actions/postActions';
import {connect} from 'react-redux';
import "./PostDetails.css";

class PostDetails extends Component {
  state = {
    post: []
  }
  urlify(text) {
    if (typeof(text) == "undefined") return ""
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function(url) {
      return '<a href="' + url + '">' + url + '</a>';
    })
  }
  componentDidMount() {
    const values = queryString.parse(this.props.location.search)
    this.props.getPost(values.id)
  }
  render(){
    const post = this.props.post
    //
    var urlified = this.urlify(post.body)
    return (
      <div className="container section post-details">
        <div className="card z-depth-0">
          <div className="card-content">
            <span className="card-title"><h5>{post.title}</h5></span>
          </div>
          <div className="card-action">
            <div className="body"dangerouslySetInnerHTML={{__html: "<p>" + urlified + "</p>"}} />
            <div className="date">{post.reg_date}</div>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
      post: state.post.post
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getPost: (id) => dispatch(getPost(id))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PostDetails);

